import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import EnrollmentPlatform from "../../../components/uba-enrollment-platform";
import SEO from "../../../components/seo";
import { Helmet } from "react-helmet";

import { platform, PlatformContext } from "../../../context";

const Enrollment = () => {
  return (
    <PlatformContext.Provider
      value={{
        quotes: false,
        enrollment: true,
      }}
    >
      <Helmet>
        <title>Enrollment Tool | My Aspire Quotes</title>
      </Helmet>
      <EnrollmentPlatform />
    </PlatformContext.Provider>
  );
};

export default Enrollment;
