import React, { useState, useEffect, useMemo } from "react";

import { Container, DatePicker, Icon, TextInput, Select } from "../../..";

import * as css from "./style.module.scss";
import Lottie from "react-lottie-player";
import lottieJson from "../../../../lotties/engine.json";

import { Button, Radio, Space } from "antd";
import { connect } from "react-redux";

import moment from "moment";
import { handleInputChange } from "../../../../utils";

import _ from "lodash";

import Toastify from "toastify-js";

const parseDate = (date: string) => {
  const [year, month, day] = date.split("-");
  return `${month}/${day}/${year}`;
};

const AdditionalInfoRaw = (props) => {
  const plans = useMemo(
    () => JSON.parse(localStorage.getItem("uba_plans")),
    []
  );
  const UbaAdditionalInfo = useMemo(
    () => JSON.parse(localStorage.getItem("additional_info"))?.UBA || [],
    []
  );
  const [loading, setLoading] = useState(true);
  const isPrimarySsnRequired = plans.some((plan) =>
    plan.general_info?.required_member_information.some(
      (r) => r.role === "primary" && r.required_info.includes("ssn")
    )
  );
  const [isEligibilityToastOpen, setIsEligibilityToastOpen] = useState(false);
  const isSpouseSsnRequired = plans.some((plan) =>
    plan.general_info?.required_member_information.some(
      (r) => r.role === "spouse" && r.required_info.includes("ssn")
    )
  );
  const hasEligibilityQuestions = plans.some(
    (plan) => plan.general_info?.eligibility_questions.length > 0
  );
  const hasAdditionalQuestions = plans.some(
    (plan) => plan.general_info?.additional_questions.length > 0
  );
  const [answers, setAnswers] = useState<any>([]);
  const [additionalAnswers, setAdditionalAnswers] = useState<any>([]);
  const initialAdditionalAnswers = useMemo(
    () =>
      plans.reduce((acc, plan) => {
        acc[`additional_questions-${plan.plan_id.value}`] =
          plan.general_info?.additional_questions.map((q) => ({
            question_id: q.question_id,
            answer: undefined,
          }));
        return acc;
      }, {}),
    [plans]
  );

  const hasNotExpectedAnswers = plans.some((plan) =>
    plan.general_info?.eligibility_questions.some((q) => {
      const currentQuestionAnswer = answers[
        `questions-${plan.plan_id.value}`
      ]?.find((a) => a.question_id === q.question_id);
      if (!currentQuestionAnswer) return false;
      if (q.expected_answer.includes(currentQuestionAnswer?.answer)) {
        return false;
      } else {
        return true;
      }
    })
  );

  React.useEffect(() => {
    if (hasNotExpectedAnswers) {
      if (isEligibilityToastOpen) return;
      Toastify({
        text: "Based on your answer we are unable to issue coverage.",
        duration: 2000,
        newWindow: true,
        close: true,
        gravity: "top",
        position: "center",
        backgroundColor: "#D33F49",
        stopOnFocus: true,
        className: "notification",
        callback: () => setIsEligibilityToastOpen(false),
      }).showToast();
      setIsEligibilityToastOpen(true);
    }
  }, [hasNotExpectedAnswers]);

  React.useEffect(() => {
    setAdditionalAnswers(initialAdditionalAnswers);
  }, [initialAdditionalAnswers]);

  const answerQuestion = (questionId, answer, planId) => {
    const newAnswer = {
      question_id: questionId,
      answer: answer,
    };
    const newAnswers = answers[`questions-${planId}`]
      ? answers[`questions-${planId}`].filter(
          (a) => a.question_id !== questionId
        )
      : [];
    setAnswers({
      ...answers,
      [`questions-${planId}`]: [...newAnswers, newAnswer],
    });
    props.setAdditionalInfo({
      key: `questions-${planId}`,
      value: [...newAnswers, newAnswer],
    });
  };

  const answerAdditionalQuestion = (questionId, answer, planId) => {
    const newAnswer = {
      question_id: questionId,
      answer: answer,
    };
    const newAnswers = additionalAnswers[`additional_questions-${planId}`]
      ? additionalAnswers[`additional_questions-${planId}`].filter(
          (a) => a.question_id !== questionId
        )
      : [];
    setAdditionalAnswers((prevState) => ({
      ...prevState,
      [`additional_questions-${planId}`]: [...newAnswers, newAnswer],
    }));
    props.setAdditionalInfo({
      key: `additional_questions-${planId}`,
      value: [...newAnswers, newAnswer],
    });
  };

  const totalEligibilityQuestions = plans.reduce(
    (acc, p) => p.general_info?.eligibility_questions?.length + acc,
    0
  );
  const totalAdditionalQuestions = plans.reduce(
    (acc, p) => p.general_info?.additional_questions?.length + acc,
    0
  );

  const draftDay = props.draftDay;

  useEffect(() => {
    new Promise((resolve) => setTimeout(resolve, 1200)).then(() => {
      setLoading(false);
    });
  }, []);
  const params = JSON.parse(localStorage.getItem("uba_params"));
  const additionalInfo =
    JSON.parse(localStorage.getItem("additional_info")).UBA || {};
  params.dependents = params.dependents ? JSON.parse(params.dependents) : [];
  const spouse = props.applicants.spouse;
  const dependents = props.applicants.dependents || [];

  const isValid = () => {
    let spouseValid = true;
    if (
      spouse &&
      (!props.spouse.first_name ||
        !props.spouse.last_name ||
        !props.spouse.date_of_birth ||
        (isSpouseSsnRequired && !props.ssn_data?.spouse))
    ) {
      spouseValid = false;
    }

    let dependentsValid = true;

    dependents.forEach((d) => {
      if (!d.first_name || !d.last_name || !d.date_of_birth) {
        dependentsValid = false;
      }
    });

    const hasNotExpectedAnswers = plans.some((plan) =>
      plan.general_info?.eligibility_questions.some((q) => {
        const currentQuestionAnswer = answers[
          `questions-${plan.plan_id.value}`
        ]?.find((a) => a.question_id === q.question_id);
        if (q.expected_answer.includes(currentQuestionAnswer?.answer)) {
          return false;
        } else {
          return true;
        }
      })
    );

    const totalAnswers = Object.entries(answers).reduce((acc, [key, value]) => {
      return acc + value?.length || 0;
    }, 0);

    const totalAdditionalAnswers = Object.entries(additionalAnswers).reduce(
      (acc, [key, value]) => {
        const answeredQuestions = value?.filter((a) => a.answer);
        return acc + answeredQuestions?.length || 0;
      },
      0
    );

    return (
      !spouseValid ||
      !dependentsValid ||
      !props.city ||
      !props.date_of_birth ||
      !props.selectedEffectiveDate ||
      (isPrimarySsnRequired && !props.ssn_data?.primary) ||
      (hasAdditionalQuestions &&
        totalAdditionalAnswers !== totalAdditionalQuestions) ||
      (hasEligibilityQuestions && totalAnswers !== totalEligibilityQuestions) ||
      hasNotExpectedAnswers
    );
  };

  return loading ? (
    <Container
      style={{
        display: "flex",
        width: "100vw",
        marginTop: "60px",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Lottie
        loop
        animationData={lottieJson}
        play
        style={{ width: 300, height: 300 }}
      />
    </Container>
  ) : (
    <Container className={css.additionalInfoContainer}>
      {hasEligibilityQuestions && (
        <>
          <Container className={css.additionalInfoIconAndTitle}>
            <Container className={css.additionalInfoIcon}>
              <Icon fill="#004C64" icon="additional-info" />
            </Container>
            <Container className={css.additionalInfoTitle}>
              Eligibility Questions
            </Container>
          </Container>
          {plans.map((plan) => (
            <>
              <Container className={css.questionPlanName}>
                {plan.plan_name.value} questions
              </Container>
              {plan?.general_info?.eligibility_questions.map((q, index) => {
                const planAnswers = answers[`questions-${plan.plan_id.value}`];
                const currentQuestionAnswer = planAnswers?.find(
                  (a) => a.question_id === q.question_id
                );
                const isCurrentQuestionOk = q.expected_answer.includes(
                  currentQuestionAnswer?.answer
                );

                return (
                  <Container
                    className={css.eligibilityQuestion}
                    key={q.question_id}
                  >
                    <Container style={{ display: "flex" }}>
                      <Container className={css.eligibilityQuestionIndex}>
                        {index + 1}.
                      </Container>
                      <Container
                        className={`${css.eligibilityQuestionText} ${
                          currentQuestionAnswer
                            ? isCurrentQuestionOk
                              ? css.eligibilityQuestionOk
                              : css.eligibilityQuestionError
                            : ""
                        } `}
                      >
                        <div dangerouslySetInnerHTML={{ __html: q.question }} />{" "}
                      </Container>
                    </Container>
                    <Container
                      className={`${css.eligibilityQuestionRadio} hidden-on-mobile`}
                    >
                      <Radio.Group
                        buttonStyle="solid"
                        size="large"
                        onChange={(e) =>
                          answerQuestion(
                            q.question_id,
                            e.target.value,
                            plan.plan_id.value
                          )
                        }
                      >
                        <Space direction="vertical">
                          {q.answers.map((a) => (
                            <Radio value={a}>{a}</Radio>
                          ))}
                        </Space>
                      </Radio.Group>
                    </Container>
                    <Container
                      className={`${css.eligibilityQuestionRadio} hidden-on-web`}
                    >
                      <Radio.Group
                        buttonStyle="solid"
                        size="large"
                        onChange={(e) =>
                          answerQuestion(
                            q.question_id,
                            e.target.value,
                            plan.plan_id.value
                          )
                        }
                      >
                        <Space direction="horizontal">
                          {q.answers.map((a) => (
                            <Radio className={css.radio} value={a}>
                              {a}
                            </Radio>
                          ))}
                        </Space>
                      </Radio.Group>
                    </Container>
                  </Container>
                );
              })}
            </>
          ))}
        </>
      )}
      {hasAdditionalQuestions && (
        <>
          <Container className={css.additionalInfoIconAndTitle}>
            <Container className={css.additionalInfoIcon}>
              <Icon fill="#004C64" icon="additional-info" />
            </Container>
            <Container className={css.additionalInfoTitle}>
              Additional Questions
            </Container>
          </Container>
          {plans.map((plan) =>
            plan?.general_info?.additional_questions.map((q, index) => (
              <Container
                className={css.eligibilityQuestion}
                key={q.question_id}
              >
                <Container style={{ display: "flex" }}>
                  <Container className={css.eligibilityQuestionIndex}>
                    {index + 1}.
                  </Container>
                  <Container className={`${css.eligibilityQuestionText}  `}>
                    <div dangerouslySetInnerHTML={{ __html: q.question }} />
                  </Container>
                </Container>
                <Container
                  className={css.additionalQuestionsInput}
                  style={{ minWidth: "35%" }}
                >
                  <>
                    {q.question_type === "select" && (
                      <Select
                        size="large"
                        onChange={(value) => {
                          answerAdditionalQuestion(
                            q.question_id,
                            value,
                            plan.plan_id.value
                          );
                        }}
                        value={
                          props[`additional_questions-${plan.plan_id.value}`]
                            ? props[
                                `additional_questions-${plan.plan_id.value}`
                              ].find((a) => a.question_id == q.question_id)
                                ?.answer
                            : null
                        }
                        before={
                          <Container
                            style={{ paddingRight: "66px", fontWeight: 500 }}
                          >
                            {q.answer_label}
                          </Container>
                        }
                        options={q.answer_options.map((a) => {
                          return {
                            label: a,
                            value: a,
                          };
                        })}
                      />
                    )}
                    {q.question_type === "text" && (
                      <TextInput
                        type="text"
                        value={
                          props[`additional_questions-${plan.plan_id.value}`]
                            ? props[
                                `additional_questions-${plan.plan_id.value}`
                              ].find((a) => a.question_id == q.question_id)
                                ?.answer
                            : ""
                        }
                        onChange={(e) => {
                          answerAdditionalQuestion(
                            q.question_id,
                            e.target.value,
                            plan.plan_id.value
                          );
                        }}
                        focusedlabel={q.answer_label}
                        placeholder={q.answer_label}
                      />
                    )}
                    {q.question_type === "date" && (
                      <DatePicker
                        value={
                          props[`additional_questions-${plan.plan_id.value}`]
                            ? props[
                                `additional_questions-${plan.plan_id.value}`
                              ].find((a) => a.question_id == q.question_id)
                                ?.answer
                            : null
                        }
                        onChange={(value) => {
                          answerAdditionalQuestion(
                            q.question_id,
                            value,
                            plan.plan_id.value
                          );
                        }}
                        before={q.answer_label}
                        focusedlabel={q.answer_label}
                      />
                    )}
                  </>
                </Container>
              </Container>
            ))
          )}
        </>
      )}
      <Container className={css.additionalInfoIconAndTitle}>
        <Container className={css.additionalInfoIcon}>
          <Icon fill="#004C64" icon="additional-info" />
        </Container>
        <Container className={css.additionalInfoTitle}>
          Enter your personal info:
        </Container>
      </Container>
      <Container className={css.inputs}>
        <Container className={css.applicantContainer}>
          <Container className={css.applicantTitle}>
            <Icon
              fill="#89D8F0"
              className={css.applicantIcon}
              icon="user-circle"
            />{" "}
            Main Applicant:
          </Container>
          <Container>
            <Container>
              {isPrimarySsnRequired && (
                <Container className={css.firstNameInputContainer}>
                  <TextInput
                    onChange={(e) => {
                      props.setAdditionalInfo({
                        key: "ssn_data",
                        value: {
                          ...props.ssn_data,
                          primary: handleInputChange(e),
                        },
                      });
                    }}
                    type="text"
                    value={props.ssn_data?.primary || ""}
                    focusedlabel="SSN"
                    placeholder="SSN"
                  />
                </Container>
              )}
              <Container className={css.birthdateContainer}>
                <DatePicker
                  name="test-enrollment-enrollment-tools-additionalinfo-input-datebirth"
                  disabled
                  value={
                    typeof props.date_of_birth === "object"
                      ? props.date_of_birth
                      : typeof props.date_of_birth === "string"
                      ? moment(props.date_of_birth)
                      : null
                  }
                  onChange={(e) => {
                    props.setAdditionalInfo({ key: "date_of_birth", value: e });
                  }}
                  before={"Date Of Birth"}
                  focusedlabel="Date of Birth"
                />
              </Container>
            </Container>
            <Container></Container>
          </Container>
        </Container>

        {spouse && (
          <Container className={css.applicantContainer}>
            <Container className={css.applicantTitle}>
              <Icon
                fill="#89D8F0"
                className={css.applicantIcon}
                icon="spouse-circle"
              />{" "}
              Spouse:
            </Container>
            <Container>
              <Container>
                <Container className={css.firstNameInputContainer}>
                  <TextInput
                    onChange={(e) => {
                      props.setAdditionalInfo({
                        key: "spouse",
                        value: {
                          ...props.spouse,
                          first_name: handleInputChange(e),
                        },
                      });
                    }}
                    value={props.spouse.first_name}
                    focusedlabel="Fist Name"
                    placeholder="First Name"
                  />
                </Container>
                <Container className={css.lastNameInputContainer}>
                  <TextInput
                    onChange={(e) => {
                      props.setAdditionalInfo({
                        key: "spouse",
                        value: {
                          ...props.spouse,
                          last_name: handleInputChange(e),
                        },
                      });
                    }}
                    value={props.spouse.last_name}
                    placeholder="Last Name"
                    focusedlabel="Last Name"
                  />
                </Container>
                {isSpouseSsnRequired && (
                  <Container className={css.firstNameInputContainer}>
                    <TextInput
                      onChange={(e) => {
                        props.setAdditionalInfo({
                          key: "ssn_data",
                          value: {
                            ...props.ssn_data,
                            spouse: handleInputChange(e),
                          },
                        });
                      }}
                      type="text"
                      value={props.ssn_data?.spouse || ""}
                      focusedlabel="SSN"
                      placeholder="SSN"
                    />
                  </Container>
                )}
                <Container className={css.birthdateContainer}>
                  <DatePicker
                    disabled
                    value={
                      typeof props.spouse.date_of_birth === "object"
                        ? props.spouse.date_of_birth
                        : typeof props.spouse.date_of_birth === "string"
                        ? moment(props.spouse.date_of_birth)
                        : null
                    }
                    onChange={(e) => {
                      props.setAdditionalInfo({
                        key: "spouse",
                        value: { ...props.spouse, date_of_birth: e },
                      });
                    }}
                    before={"Date Of Birth"}
                    focusedlabel="Date of Birth"
                  />
                </Container>
              </Container>
              <Container></Container>
            </Container>
          </Container>
        )}
        {dependents.map((dep, ind) => {
          return (
            <Container className={css.applicantContainer}>
              <Container className={css.applicantTitle}>
                <Icon
                  fill="#89D8F0"
                  className={css.applicantIcon}
                  icon="dependent-circle"
                />{" "}
                Dependent {ind + 1}:
                <Container className={css.dependentInfoContainer}>
                  <span>
                    Age <strong>{dep.age}</strong>
                  </span>
                  <span>
                    Gender{" "}
                    <Icon
                      icon={`gender-${dep.gender.toLowerCase()}`}
                      className={css.dependentGender}
                    />
                  </span>
                </Container>
              </Container>
              <Container>
                <Container>
                  <Container className={css.firstNameInputContainer}>
                    <TextInput
                      value={dependents[ind].first_name}
                      placeholder="First Name"
                      focusedlabel="First Name"
                      onChange={(e) => {
                        dependents[ind].first_name = handleInputChange(e);
                        props.setAdditionalInfo({
                          key: "dependents",
                          value: dependents,
                        });
                      }}
                    />
                  </Container>
                  <Container className={css.lastNameInputContainer}>
                    <TextInput
                      value={dependents[ind].last_name}
                      placeholder="Last Name"
                      focusedlabel="Last Name"
                      onChange={(e) => {
                        dependents[ind].last_name = handleInputChange(e);
                        props.setAdditionalInfo({
                          key: "dependents",
                          value: dependents,
                        });
                      }}
                    />
                  </Container>
                  <Container className={css.birthdateContainer}>
                    <DatePicker
                      disabled
                      value={
                        typeof dependents[ind].date_of_birth === "object"
                          ? dependents[ind].date_of_birth
                          : typeof dependents[ind].date_of_birth === "string"
                          ? moment(dependents[ind].date_of_birth)
                          : null
                      }
                      before={"Date Of Birth"}
                      onChange={(e) => {
                        dependents[ind].date_of_birth = e;
                        props.setAdditionalInfo({
                          key: "dependents",
                          value: dependents,
                        });
                      }}
                      focusedlabel="Date of Birth"
                    />
                  </Container>
                </Container>
              </Container>
            </Container>
          );
        })}
      </Container>
      <Container className={css.lastInputs}>
        <Container className={css.householdContainer}>
          <Container className={`${css.householdIconAndTitle}`}>
            <Container className={`${css.householdIcon} `}>
              <Icon fill="#004C64" icon="building" />
            </Container>
            <Container>
              <Container
                style={{ marginLeft: "2px" }}
                className={css.householdTitle}
              >
                Complete Household Address:
              </Container>
            </Container>
          </Container>
          <Container className={css.addressInputs}>
            <Container className={css.streetAndNumberContainer}>
              <Container className={css.addressStreetNameInput}>
                <TextInput
                  name="test-enrollment-enrollment-additionalinfo-input-street-adress"
                  value={props.address}
                  onChange={(e) => {
                    props.setAdditionalInfo({
                      key: "address",
                      value: handleInputChange(e),
                    });
                  }}
                  placeholder="Street Address"
                  focusedlabel="Street Address"
                />
              </Container>
            </Container>

            <Container className={css.addressCityInput}>
              <TextInput
                name="test-enrollment-enrollment-additionalinfo-input-city"
                value={props.city}
                onChange={(e) => {
                  props.setAdditionalInfo({
                    key: "city",
                    value: handleInputChange(e),
                  });
                }}
                placeholder="City"
                focusedlabel="City"
              />
            </Container>
          </Container>
        </Container>
        <Container className={css.coverageContainer}>
          <Container className={`${css.householdIconAndTitle}`}>
            <Container className={`${css.householdIcon} `}>
              <Icon fill="#004C64" icon="heart-schedule" />
            </Container>
            <Container
              style={{ marginLeft: "2px" }}
              className={css.householdTitle}
            >
              Select the Effective Date:
            </Container>
          </Container>

          <Container className={css.coverageDates}>
            <Container
              className={`${css.coverageDateInput} ${css.coverageDraftDay}`}
            >
              <Select
                name="test-enrollment-enrollment-additionalinfo-select-police-length"
                value={draftDay}
                size="large"
                before={
                  <Container style={{ paddingRight: "66px", fontWeight: 500 }}>
                    Effective Date
                  </Container>
                }
                onChange={(e) => {
                  props.setAdditionalInfo({
                    key: "selectedEffectiveDate",
                    value: e,
                  });
                }}
                options={UbaAdditionalInfo.effective_date_options.map((m) => {
                  return {
                    label: parseDate(m),
                    value: m,
                  };
                })}
              />
            </Container>
          </Container>
        </Container>
      </Container>

      <Container className={css.buttonsContainer}>
        <Container className={css.nextButton}>
          <Button
            disabled={isValid()}
            onClick={() => {
              props.next && props.next();
            }}
            type="primary"
            shape="round"
          >
            Next
          </Button>
        </Container>
      </Container>
    </Container>
  );
};

const mapStateToProps = ({
  agentMetadata,
  baseUrl,
  enrollment,
  applicants,
}) => {
  return {
    applicants,
    agentMetadata,
    baseUrl,
    enrollment,
    ...enrollment.universal.additionalInfo,
    date_of_birth: enrollment.universal.additionalInfo.date_of_birth
      ? enrollment.universal.additionalInfo.date_of_birth
      : applicants.date_of_birth,
    spouse:
      Object.keys(enrollment.universal.additionalInfo.spouse).length > 0
        ? enrollment.universal.additionalInfo.spouse
        : applicants.spouse,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setAdditionalInfo: (payload) =>
      dispatch({ type: `SET_ADDITIONAL_INFO_UNIVERSAL`, payload }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdditionalInfoRaw);
