import React, { useState, useEffect } from "react";

import EnrollmentFinalStep from "../enrollment-final-step";
import MasterPage from "./components/master-page";
import EligibiltyQuestions from "./components/eligibility-questions";
import AdditionalInfo from "./components/additional-info/index";
import Checkout from "./components/checkout";
import ThankYou from "./components/thank-you";

import Lottie from "react-lottie-player";
import lottieJson from "../enrollment-platform/loading.json";
import { Container, Icon } from "..";

import { connect } from "react-redux";

new Promise((resolve) => setTimeout(resolve, 3000));

const EnrollmentPlatformRaw = ({ data, setApplicationsUniversal }) => {
  const [loading, setLoading] = useState(true);
  const [currentStep, setCurrentStep] = useState(0);
  useEffect(() => {
    setTimeout(() => {
      const params = JSON.parse(localStorage.getItem("uba_params"));
      const spouse = params.spouse
        ? JSON.parse(JSON.parse(params.spouse))
        : null;
      const dependents = params.dependents
        ? JSON.parse(JSON.parse(params.dependents))
        : null;
      const monthlyValue = JSON.parse(localStorage.getItem("uba_plans")).reduce(
        (a, b) => {
          return a + b.premium.value;
        },
        0
      );
      setApplicationsUniversal({
        spouse,
        dependents,
        monthlyValue,
        date_of_birth: params?.date_of_birth,
      });

      setLoading(false);
    }, 1000);
  }, []);

  if (currentStep == 3) {
    return <EnrollmentFinalStep />;
  }

  return loading ? (
    <Container
      style={{
        display: "flex",
        width: "100vw",
        height: "100vh",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Container>
        <Lottie
          loop
          animationData={lottieJson}
          play
          style={{ width: 300, height: 300 }}
        />
        <Container style={{ textAlign: "center" }}>Enrollment Tool</Container>
      </Container>
    </Container>
  ) : (
    <MasterPage currentStep={currentStep} setCurrentStep={setCurrentStep}>
      {currentStep == 0 && (
        <AdditionalInfo
          next={() => {
            setCurrentStep(2);
          }}
        />
      )}

      {currentStep == 2 && (
        <Checkout
          setCurrentStep={setCurrentStep}
          next={() => {
            setCurrentStep(3);
          }}
        />
      )}
    </MasterPage>
  );
};

const mapStateToProps = ({ agentMetadata, baseUrl, enrollment, quoteId }) => {
  return {
    agentMetadata,
    baseUrl,
    quoteId,
    enrollment: { ...enrollment },
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setApplicationsUniversal: (payload) =>
      dispatch({ type: `SET_APPLICATIONS_UNIVERSAL`, payload }),
    setAdditionalInfo: (payload) =>
      dispatch({ type: `SET_ADDITIONAL_INFO_UNIVERSAL`, payload }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EnrollmentPlatformRaw);
